import Spline from '@splinetool/react-spline';

export default function App() {
  return (
    <>
      <Spline scene="https://prod.spline.design/YGq3nmoRCpRangsY/scene.splinecode" />
      <p  style={{color: "GrayText", position: "absolute", top: "10px", left: "10px"}}>Created by Akshay Rana using Spline Tool</p>
    </>
  );
}
